<template>
  <v-form lazy-validation>
    <v-row justify="center">
      <v-dialog
        v-model="open"
        persistent
        scrollable
        max-width="500px"
        transition="scale-transition"
      >
        <v-card outlined>
          <v-card-title class="title font-weight-bold">
            {{ $t("e_test.code_of_conduct") }}
            <div style="position: absolute; right: 25px;">
              <v-icon :disabled="disableButton" @click="toggleDialog"
                >mdi-close</v-icon
              >
            </div>
          </v-card-title>
          <v-divider />
          <v-card-text style="height: 500px; overflow-y: auto;">
            <v-row class="pt-4">
              <v-col cols="12">
                <quill-editor
                  v-model="bodyRequest"
                  :disabled="disableButton"
                  :options="editorOption"
                />
                <div v-if="copy">
                  <v-switch
                    v-model="isCopy"
                    :loading="loadingCopy"
                    :label="$t('e_test.copy_coc')"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-row no-gutters justify="end">
              <v-btn
                :disabled="disableButton"
                outlined
                small
                color="primary"
                depressed
                @click="toggleDialog"
              >
                {{ $t("app.cancel") }}
              </v-btn>
              <v-spacer />
              <v-btn
                :loading="loadingSave"
                small
                dark
                class="gradient-primary"
                depressed
                @click="save"
              >
                {{ $t("app.save") }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-form>
</template>

<script>
import {
  ImageExtend,
  QuillWatch,
  container
} from "@/plugins/quill-image-extend-module";
import { getToken } from "@/utils/storage";
import { deleteFile } from "@/api/admin/admin";
import { sliceAwsPath } from "@/utils/uploadS3";
import { quillEditor, Quill } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import katex from "katex";
import "katex/dist/katex.min.css";

Quill.register("modules/ImageExtend", ImageExtend);

import {
  getCOCGlobal,
  setCOCGlobal,
  setCOCLocal
} from "@/api/admin/academic/eTest";

export default {
  props: {
    open: Boolean,
    copy: Boolean,
    isGlobal: Boolean,
    data: Object
  },
  watch: {
    open(value) {
      if (value) {
        this.tempImages = [];
        this.bodyRequest = "";
        this.isCopy = false;
        if (this.isGlobal) this.retriveCOCGlobal();
        if (this.data) this.bodyRequest = this.data.regulation;
      }
    },
    isCopy(value) {
      if (value) {
        this.retriveCOCGlobal();
      } else {
        const tempData = this.bodyRequest?.replace(this.cocGlobal, "") ?? "";
        this.bodyRequest = tempData;
      }
    }
  },
  created() {
    window.katex = katex;
  },
  components: {
    quillEditor
  },
  data() {
    return {
      cocGlobal: "",
      isCopy: false,
      loadingCopy: false,
      disableButton: false,
      loadingSave: false,
      moduleFileName: "CODEOFCONDUCT",
      tempImages: [],
      editorOption: {
        modules: {
          toolbar: {
            container: container,
            handlers: {
              image: function() {
                QuillWatch.emit(this.quill.id);
              }
            }
          },
          ImageExtend: {
            loading: true,
            name: "file",
            editForm: formData => {
              formData.set("path", "code-of-conduct/images");
              formData.set("module", this.moduleFileName);
            },
            action: process.env.VUE_APP_BASE_API + "aws/upload",
            headers: xhr => {
              xhr.setRequestHeader("Authorization", "Bearer " + getToken());
              xhr.setRequestHeader("x-lang", this.$store.getters.g_language);
              xhr.setRequestHeader("x-institution", window.id_school);
            },
            response: res => {
              this.tempImages.push(res.data.path);
              return res.data.path;
            }
          }
        }
      },
      bodyRequest: ""
    };
  },
  methods: {
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    async retriveCOCGlobal() {
      this.disableButton = true;
      if (this.isCopy) this.loadingCopy = true;
      try {
        const response = await getCOCGlobal();
        if (response.data.code === 1) {
          const data = response.data.data.regulation;
          if (this.copy) {
            this.cocGlobal = data;
            const global = data;
            const local = this.bodyRequest;
            this.bodyRequest = local + global;
          } else {
            this.bodyRequest = data;
          }
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.disableButton = false;
      if (this.isCopy) this.loadingCopy = false;
    },
    async save() {
      this.disableButton = true;
      this.loadingSave = true;

      try {
        const response = this.isGlobal
          ? await setCOCGlobal({ regulation: this.bodyRequest })
          : await setCOCLocal({
              regulation: this.bodyRequest,
              etest_class: this.data.etest_class
            });
        if (response.data.code === 1) {
          this.tempImages = [];
          this.snackBar(true, response.data.message);
          this.toggleDialog();
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }

      this.disableButton = false;
      this.loadingSave = false;
    },
    async removeAllFiles() {
      if (this.tempImages.length) {
        await this.tempImages.map(async d => {
          const path = sliceAwsPath(d);
          await deleteFile(path, this.moduleFileName);
        });
      }
    },
    toggleDialog() {
      this.removeAllFiles();
      this.$emit("toggle");
    }
  }
};
</script>
